import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./FrequentAskQuestions.css";
import faq from './faq.png';
function FrequentAskQuestions() {
  return (
    <div id="faq" className="faq-section">
      <h1 className="faq-heading">Frequently Asked Questions</h1>
      <div className="faq-content">
        <div className="faq-left">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">What is your return policy?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="custom-typography">
                Our return policy is 30 days with a full refund. For more details, please visit our return policy page.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6">How do I track my order?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="custom-typography">
                You can track your order using the tracking number provided in your order confirmation email. Visit our order tracking page for more details.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="h6">Can I purchase items again?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="custom-typography">
                Yes, you can repurchase items from your order history. Visit your account page to view your order history and make purchases.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6">Do you offer international shipping?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="custom-typography">
                Yes, we offer international shipping to most countries. Please check our shipping information page for more details.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography variant="h6">What payment methods do you accept?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="custom-typography">
                We accept various payment methods including credit/debit cards, PayPal, and bank transfers. For a full list, please visit our payment methods page.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <Typography variant="h6">How can I contact customer support?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="custom-typography">
                You can contact our customer support through our contact us page, email, or phone. We are available 24/7 to assist you.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="faq-right">
          <img src={faq} alt="FAQ" />
        </div>
      </div>
    </div>
  );
}

export default FrequentAskQuestions;
