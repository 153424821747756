import React from "react";
import { Container, Grid, Typography, Link } from "@mui/material";
import "./Footer.css";
import logo from "../logo1.png";

function Footer() {
  return (
    <footer
      style={{ backgroundColor: "#181B1A", padding: "20px 0", color: "white" }}
    >
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <img
              src={logo}
              alt="Company Logo"
              style={{ width: "100%", maxWidth: "150px", marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <a href="#about">About Us</a>
              </li>
              <li>
                <a href="#review">Reviews</a>
              </li>
              <li>
                <a href="#how">How it works</a>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6" gutterBottom>
              Support
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link href="#" color="inherit" underline="none">
                  Contact Us
                </Link>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
              <li>
                <Link href="#" color="inherit" underline="none">
                  Help Center
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link href="#" color="inherit" underline="none">
                  Facebook
                </Link>
              </li>
              <li>
                <Link href="#" color="inherit" underline="none">
                  Twitter
                </Link>
              </li>
              <li>
                <Link href="#" color="inherit" underline="none">
                  Instagram
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          align="center"
          style={{ marginTop: "20px" }}
        >
          &copy; {new Date().getFullYear()} Nunua Chai. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
