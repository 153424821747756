import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUp.css';

const SignUp = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Set loading state

    const formData = new FormData(event.target);

    // Create data object matching the required structure
    const data = {
      email: formData.get('email'),
      username: formData.get('username'),
      phone_number: formData.get('phone').replace(/\D/g, ''),  // Remove non-digits to match the expected phone number format
      password: formData.get('password')
    };

    try {
      const response = await fetch('https://staging-chai-api.onrender.com/accounts/new/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': process.env.REACT_APP_API_KEY // Ensure this is set in your .env file
        },
        body: JSON.stringify(data) // Ensure data is sent as JSON
      });

      const text = await response.text();
      setIsLoading(false); // Stop loading when response is received

      if (response.status === 201) {
        const result = JSON.parse(text);

        // Display success message and redirect to the dashboard
        setErrorMessage(result.message || 'User created successfully. Redirecting to dashboard...');
        navigate('/Dashboard');  // Redirect to the dashboard page after successful signup
      } else if (response.status === 400) {
        const result = JSON.parse(text);
        setErrorMessage(result.error || 'An error occurred during sign-up.');
      } else {
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      console.error("Signup error: ", error);
      setIsLoading(false);  // Stop loading state on error
      setErrorMessage('An error occurred during sign-up. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <header className="header-l">
        <button className="menu-button" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </button>
        <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
          <a href="#about">About</a>
          <a href="#faqs">FAQs</a>
          <a href="#reviews">Reviews</a>
        </nav>
      </header>
      <div className="login-content">
        <img src="/logo2.png" alt="Logo" className="logo" />
        <div className="welcome-message">
          <h2>
            <span style={{ color: 'black' }} className='signup-title'>Get Started</span>
          </h2>
        </div>
        <div className="login-box">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" name="email" placeholder="johndoe@gmail.com" required />
            </div>
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <input type="text" id="username" name="username" placeholder="John_Doe" required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input type="tel" id="phone" name="phone" placeholder="0712 345 678" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" placeholder="m#P52s@ap$V" required />
            </div>
            <button type="submit" className="btn" disabled={isLoading}>
              {isLoading ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          {errorMessage && <div className="error">{errorMessage}</div>}
          <div className="footer">
            <p>
              Already have an account? <a href="/login">Log in</a>
            </p>
          </div>
          <button className="btn google-btn">
            <img src="/google.png" alt="Google logo" className="google-logo" />
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
