import React from "react";
import "./components.css";

const SideBar = ({ isActive, closeSidebar }) => {
  return (
    <div className={`sidebar ${isActive ? "active" : ""}`}>
      <button className="close-button" onClick={closeSidebar}>
        x
      </button>
      <div className="side-items">
        <a href="#about" onClick={closeSidebar}>
          About us
        </a>
        <a href="#faq" onClick={closeSidebar}>
          FAQ
        </a>
        <a href="#review" onClick={closeSidebar}>
          Reviews
        </a>
      </div>
      <div className="sidebar-bottom">
        <button className="get-started">Sign up</button>
      </div>
    </div>
  );
};

export default SideBar;
