import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';

function storeSession(token) {
  console.log("Token stored: ", token);
  localStorage.setItem('token', token);
  localStorage.setItem('loginTimestamp', Date.now());
}

function Login() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = {
      email: formData.get('email'),
      password: formData.get('password')
    };

    try {
      const response = await fetch('https://staging-chai-api.onrender.com/accounts/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        storeSession(result.token); // Store user token and timestamp in localStorage
        setErrorMessage('');
        navigate('/Dashboard'); // Redirect to dashboard
      } else {
        const result = await response.json();
        setErrorMessage(result.detail || 'An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('Login error: ', error);
      setErrorMessage('An error occurred during login. Please check your network connection and try again.');
    }
  };

  return (
    <div className="login-container">
      <header className="header-l">
        <button className="menu-button" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </button>
        <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
          <Link to="/about">About</Link>
          <Link to="/faqs">FAQs</Link>
          <Link to="/reviews">Reviews</Link>
        </nav>
      </header>
      <div className="login-content">
        <img src="/logo2.png" alt="Logo" className="logo" />
        <div className="welcome-message">
          <h2>
            <span style={{ color: 'black' }}>Welcome</span>{' '}
            <span style={{ color: '#E8891D' }}>Back!</span>
          </h2>
        </div>

        <div className="login-box">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="password" id="password" name="password" required />
            </div>
            <button type="submit" className="btn">
              Log in
            </button>
          </form>
          {errorMessage && <div className="error">{errorMessage}</div>}
          <div className="footer">
            <p>
              Not yet registered? <Link to="/RegisterPage">Sign up</Link>
            </p>
          </div>
          <Link to="/PasswordRecovery" className="forgot-password-link">
            Forgot Password?
          </Link>
          <button className="btn google-btn">
            <img src="/google.png" alt="Google logo" className="google-logo" />
            Sign in with Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
