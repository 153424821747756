import React from 'react';
import './HowItWorks.css'

function HowItWorks() {
    return (
        <div className='how-container' id='how'>
        <h1 className='how-heading'>How It Works</h1>
        <div className='how-content'>
      
        </div>
        
        </div>
    );
    };
    export default HowItWorks;
