//landing page for the app
import React from "react";
import NavBar from "../components/NavBar.js";
import MainSection from "../components/Mainsection/MainSection.js";
import About from "./About.js";
import HowItWorks from "./HowItWorks.js";
import FrequentAskQuestions from "./FrequentAskQuestions.js";
import Footer from "../components/Footer.js";

function LandingPage() {
  return (
    <div>
      <NavBar />
      <MainSection/>
     <About/>
     <HowItWorks />
     <FrequentAskQuestions />
     <Footer />
    </div>
  );
}

export default LandingPage;
