import { FiSearch } from "react-icons/fi";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Box,
  IconButton,
  TextField,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import "../components.css";

function SearchBar() {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const inputRef = useRef(null);

  async function searchFromAPI(query) {
    if (!query.trim()) {
      setResults([]);
      return;
    }

    const apiUrl = "https://staging-chai-api.onrender.com";
    const apiKey = process.env.REACT_APP_X_API_KEY;
    try {
      setLoading(true);
      const response = await fetch(
        `${apiUrl}/accounts/?creator=${encodeURIComponent(query)}`,
        {
          method: "GET",
          headers: {
            "X-api-key": apiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();

      setResults(data || []); // Adjust according to your API response structure
      setError("");
    } catch (error) {
      console.error("Error fetching data from API:", error);
      setResults([]);
      setError("Failed to fetch search results. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const handleSearchClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [open]);

  const handleSearchChange = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    await searchFromAPI(query); // Call the API search function
  };

  return (
    <div className="search-bar">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          width: "250px", // Fixed width for the search bar
          fontSize: "16px",
          fontFamily: "Nexa-Bold",
          borderRadius: "30px",
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
          position: "relative",
        }}
        onClick={handleSearchClick}
      >
        <IconButton sx={{ pointerEvents: "none", padding: 0 }}>
          <FiSearch style={{ color: "#e67e22", fontSize: "18px" }} />
        </IconButton>
        <TextField
          placeholder="Search Creators"
          variant="standard"
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            sx: {
              flex: 1,
              paddingLeft: "5px", // Adjust padding to match the design
              fontSize: "inherit",
              fontFamily: "Nexa-Bold",
              cursor: "pointer",
              color: "#006373",
            },
          }}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            height: "calc(100vh - 60px)",
            width: "500px",
            overflowY: "auto",
            padding: "0",
            borderRadius: "15px",
            backgroundColor: "#fff",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0", margin: "0" }}>
          <Paper
            elevation={0}
            component="form"
            sx={{
              p: "15px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              backgroundColor: "#f0f0f0",
            }}
          >
            <IconButton sx={{ padding: 0 }}>
              <FiSearch style={{ color: "#e67e22", fontSize: "18px" }} />
            </IconButton>
            <InputBase
              inputRef={inputRef}
              sx={{
                ml: 1,
                flex: 1,
                height: "auto",
                fontSize: "16px",
                borderRadius: "15px",
                backgroundColor: "#f0f0f0",
                fontFamily: "Nexa-Bold",
                cursor: "pointer",
                color: "#006373",
              }}
              placeholder="Search Creator"
              value={searchQuery}
              onChange={handleSearchChange}
              autoFocus
              inputProps={{ "aria-label": "Search Creator" }}
            />
          </Paper>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <p>Searching...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <List>
              {results.map((data, index) => (
                <ListItem
                  key={index}
                  sx={{
                    padding: 0,
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt={data.stage_name} src={data.profile_image} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      textTransform: "capitalize",
                      color: "#006373",
                      fontFamily: "Nexa-Bold",
                    }}
                    primary={data.stage_name}
                    secondary={`@${data.username}`}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SearchBar;
