import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Anzenza from './images/Anzenza.png';
import Azziad from './images/Azziad.png';
import Flaqo from './images/Flaqo.png';
import Grauchi from './images/Grauchi.png';
import '../components/components.css';

function About() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideTrackRef = useRef(null);
  const slides = [
    { img: Anzenza },
    { img: Azziad },
    { img: Flaqo },
    { img: Grauchi },
    // Add more slide objects here
  ];

  const totalSlides = slides.length;
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const getSlideWidth = () => {
    if (isMdUp) return 150; // desktop
    if (isSmUp) return 120; // tablet
    return 80; // mobile
  };

  const slideWidth = getSlideWidth();
  const slideMargin = 20; // margin between slides

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, 3000);

    return () => clearInterval(interval);
  }, [totalSlides]);

  useEffect(() => {
    if (currentSlide >= totalSlides) {
      setTimeout(() => {
        slideTrackRef.current.style.transition = 'none';
        setCurrentSlide(0);
      }, 500);
    } else {
      slideTrackRef.current.style.transition = 'transform 0.5s ease';
    }
  }, [currentSlide, totalSlides]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Box className="about-section" id="about" sx={{ textAlign: 'center', p: 2 }}>
      <Typography variant={isSmUp ? 'h3' : 'h4'} className="about-header" sx={{ mb: 2 }}>
        What is Nunua Chai?
      </Typography>
      <Typography variant="body1" className="about-body" sx={{ mb: 4 }}>
        Nunua chai is a new platform for fans to appreciate their favourite creators. This will be helpful for both the fans and creators in the sense that creators will be able to get value directly from their audiences. Fans/consumers will be able to show their appreciation in more than just likes and following. Additionally, they will continue to get quality content from the creators.
      </Typography>
      <Typography variant={isSmUp ? 'h3' : 'h4'} className="about-heading" sx={{ mb: 2 }}>
        A platform for <span>All Creators</span>
      </Typography>
      <Box className="slider" sx={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
        <Box
          className="slide-track"
          ref={slideTrackRef}
          sx={{
            display: 'flex',
            transition: 'transform 0.5s ease',
            transform: `translateX(-${currentSlide * (slideWidth + slideMargin)}px)`,
          }}
        >
          {slides.map((slide, index) => (
            <Box
              className="slide"
              key={index}
              sx={{ minWidth: `${slideWidth}px`, marginRight: `${slideMargin}px` }}
            >
              <img src={slide.img} alt="creator" style={{ width: '100%' }} />
            </Box>
          ))}
          {slides.map((slide, index) => (
            <Box
              className="slide"
              key={`clone-${index}`}
              sx={{ minWidth: `${slideWidth}px`, marginRight: `${slideMargin}px` }}
            >
              <img src={slide.img} alt="creator" style={{ width: '100%' }} />
            </Box>
          ))}
        </Box>
        <Box className="dot-indicators" sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          {slides.map((_, index) => (
            <Box
              key={index}
              className={`dot ${index === currentSlide % totalSlides ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
              sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: index === currentSlide % totalSlides ? theme.palette.primary.main : theme.palette.grey[400],
                mx: 0.5,
                cursor: 'pointer',
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default About;
