import React from 'react';
import './TopSponsors.css';

const sponsors = [
    "cowoder", "Ivan Luchin", "anonymous", "Valentin Orient", "anonymous", 
    "Jakub Machalek", "Markus Burmeister", "anonymous", "yeni molland", "Mike Roth", 
    "Regen Barker", "WM 434 CM", "William Armstrong", "Oleksandr Silvestrenko", "anonymous"
];

const TopSponsors = () => {
    return (
        <div className="top-sponsors">
            <h2>Top Sponsors (last 2 months)</h2>
            <div className="sponsor-list">
                {sponsors.map((sponsor, index) => (
                    <div key={index} className="sponsor">
                        {sponsor}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TopSponsors;
