import React, { useState } from 'react';
import './SponsorPackages.css';

const packages = [
    { title: 'Basic', price: 'ksh.100', description: 'A short description about this tier' },
    { title: 'Gold', price: 'ksh.350', description: 'A short description about this tier', popular: true },
    { title: 'Platinum', price: 'ksh.700', description: 'A short description about this tier' },
    { title: 'Custom', description: 'Contribute an amount of your choice' }
];

const SponsorPackages = () => {
    const [formData, setFormData] = useState(
        packages.map(() => ({ name: '', number: '', amount: '', message: '' }))
    );
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedFormData = formData.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
        );
        setFormData(updatedFormData);
    };

    const handleSubmit = (index, e) => {
        e.preventDefault();
        console.log(`Form data for ${packages[index].title}:`, formData[index]);
        setModalOpen(false);
    };

    const handlePackageClick = (index) => {
        setSelectedPackage(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="sponsor-packages">
            <h2>Sponsor Packages</h2>
            <div className="packages">
                {packages.map((pkg, index) => (
                    <div 
                        key={index} 
                        className={`package package-${index} ${pkg.popular ? 'popular' : ''}`}
                    >
                        {pkg.popular && <div className="popular-tag">Most Popular</div>}
                        <h3>{pkg.title}</h3>
                        <p>{pkg.price}</p>
                        <p>{pkg.description}</p>
                        <form className="sponsor-form" onSubmit={(e) => handleSubmit(index, e)}>
                            <div>
                                <label>Your name</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Juma mzalendo"
                                    value={formData[index].name}
                                    onChange={(e) => handleChange(index, e)}
                                    required
                                    onClick={() => handlePackageClick(index)}
                                />
                            </div>
                            <div>
                                <label>Mpesa number</label>
                                <input
                                    type="text"
                                    name="number"
                                    placeholder="0712 345 678"
                                    value={formData[index].number}
                                    onChange={(e) => handleChange(index, e)}
                                    required
                                />
                            </div>
                            {pkg.title === 'Custom' && (
                                <div>
                                    <label>Amount</label>
                                    <input
                                        type="number"
                                        name="amount"
                                        placeholder="0712 345 678"
                                        value={formData[index].amount}
                                        onChange={(e) => handleChange(index, e)}
                                        required
                                    />
                                </div>
                            )}
                            <div>
                                <label>Your message (optional)</label>
                                <input
                                    type="text"
                                    name="message"
                                    placeholder=""
                                    value={formData[index].message}
                                    onChange={(e) => handleChange(index, e)}
                                />
                            </div>
                            <button type="submit" className={`sponsor-button sponsor-button-${index}`}>
                                Sponsor
                            </button>
                        </form>
                    </div>
                ))}
            </div>

            {modalOpen && selectedPackage !== null && (
                <div className={`modal ${modalOpen ? 'show' : ''}`}>
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h3>{packages[selectedPackage].title}</h3>
                        <form className="sponsor-form" onSubmit={(e) => handleSubmit(selectedPackage, e)}>
                            <div>
                                <label>Your name</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Juma mzalendo"
                                    value={formData[selectedPackage].name}
                                    onChange={(e) => handleChange(selectedPackage, e)}
                                    required
                                />
                            </div>
                            <div>
                                <label>Mpesa number</label>
                                <input
                                    type="text"
                                    name="number"
                                    placeholder="0712 345 678"
                                    value={formData[selectedPackage].number}
                                    onChange={(e) => handleChange(selectedPackage, e)}
                                    required
                                />
                            </div>
                            {packages[selectedPackage].title === 'Custom' && (
                                <div>
                                    <label>Amount</label>
                                    <input
                                        type="number"
                                        name="amount"
                                        placeholder="Enter the amount"
                                        value={formData[selectedPackage].amount}
                                        onChange={(e) => handleChange(selectedPackage, e)}
                                        required
                                    />
                                </div>
                            )}
                            <div>
                                <label>Your message (optional)</label>
                                <input
                                    type="text"
                                    name="message"
                                    placeholder="Write a message"
                                    value={formData[selectedPackage].message}
                                    onChange={(e) => handleChange(selectedPackage, e)}
                                />
                            </div>
                            <button type="submit" className={`sponsor-button sponsor-button-${selectedPackage}`}>
                                Sponsor
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SponsorPackages;
