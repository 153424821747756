import React, { useState } from 'react';
import './CreatorBanner.css';

const CreatorBanner = () => {
    const [backgroundImage, setBackgroundImage] = useState('');
    const [leftImage, setLeftImage] = useState('');
    const fileInputRefBackground = React.createRef();
    const fileInputRefLeft = React.createRef();

    const handleBackgroundImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setBackgroundImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleLeftImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setLeftImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageClick = (ref) => {
        ref.current.click();
    };

    return (
        <div 
            className="creator-banner" 
            style={{ backgroundImage: `url(${backgroundImage || 'creator-banner.png'})` }} 
            onClick={() => handleImageClick(fileInputRefBackground)}
        >
            <div className="left-contentCB">
                <img 
                    src={leftImage || 'Grauchi.png'} 
                    alt="Creator Left" 
                    className="creator-image-left" 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleImageClick(fileInputRefLeft);
                    }} 
                />
                <h2 className='Creator-Name'>Mzee Juma</h2>
            </div>
            <input 
                type="file" 
                ref={fileInputRefBackground} 
                style={{ display: 'none' }} 
                onChange={handleBackgroundImageChange} 
            />
            <input 
                type="file" 
                ref={fileInputRefLeft} 
                style={{ display: 'none' }} 
                onChange={handleLeftImageChange} 
            />
        </div>
    );
}

export default CreatorBanner;
