import React from 'react';
import './Transactions.css';

const transactions = [
  { sender: '254757043522', recipient: 'mwangi', amount: '5000.00', code: 'ws_CO_13FuPAXgnVGkZ1DCHKK17cReXM9LMLmA22', time: 'July 13, 2024, 4:38 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254720144054', recipient: 'mwangi', amount: '2000.00', code: 'ws_CO_13072024115622068720144054', time: 'July 13, 2024, 11:52 a.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '200.00', code: 'ws_CO_1307202416450718757043522', time: 'July 13, 2024, 4:11 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '200.00', code: 'ws_CO_1307202416312919547043522', time: 'July 13, 2024, 4:19 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'brayo', amount: '1.00', code: 'ws_CO_1307202418261256077043522', time: 'July 13, 2024, 6:26 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '5000.00', code: 'ws_CO_1307202417113342157043522', time: 'July 13, 2024, 5:11 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '1.00', code: 'ws_CO_1307202418311100877043522', time: 'July 13, 2024, 6:31 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'brayo', amount: '1.00', code: 'ws_CO_1307202418254006877043522', time: 'July 13, 2024, 6:25 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254789428931', recipient: 'brayo', amount: '1.00', code: 'ws_CO_14072024103195672798428931', time: 'July 14, 2024, 10:32 a.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '2000.00', code: 'ws_CO_1307202411525577577043522', time: 'July 13, 2024, 11:52 a.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '1.00', code: 'ws_CO_13072024174454005757043522', time: 'July 13, 2024, 5:41 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '1.00', code: 'ws_CO_13072024175443136757043522', time: 'July 13, 2024, 5:51 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '1.00', code: 'ws_CO_1307202416244976757043522', time: 'July 13, 2024, 4:24 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '5000.00', code: 'ws_CO_1307202419107601757043522', time: 'July 13, 2024, 7:01 p.m.', status: 'Pending', type: 'Donation' },
  { sender: '254757043522', recipient: 'mwangi', amount: '2000.00', code: 'ws_CO_1307202416103391577043522', time: 'July 13, 2024, 4:10 p.m.', status: 'Pending', type: 'Donation' },
];

const Transactions = () => {
  return (
    <div className="transactions-container">
      <table>
        <thead>
          <tr>
            <th>Sender</th>
            <th>Recipient</th>
            <th>Amount</th>
            <th>Transaction code</th>
            <th>Transaction time</th>
            <th>Status</th>
            <th>Transaction type</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.sender}</td>
              <td>{transaction.recipient}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.code}</td>
              <td>{transaction.time}</td>
              <td>{transaction.status}</td>
              <td>{transaction.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Transactions;
