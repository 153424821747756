import React from 'react';
import Header from './HeaderReusable';
import CreatorBanner from './CreatorBanner';
import SponsorPackages from './SponsorPackages';
import TopSponsors from './TopSponsors';
import Footer from './Footer';
import './Client.css';

function ClientView() {
    return (
        <div className="Client">
            <Header />
            <CreatorBanner />
            <SponsorPackages />
            <TopSponsors />
            <Footer />
        </div>
    );
}

export default ClientView;
